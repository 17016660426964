import { Routes } from '@angular/router';
import { BlocksService } from './common-theme/api/index';
import { HomeComponent } from './home/home.component';
const ɵ0 = {
    blocks_categories: ['homepage']
};
const routes = [
    {
        path: '',
        component: HomeComponent,
        data: ɵ0,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: ''
    }
];
export class AppRoutingModule {
}
export { ɵ0 };
